import React from 'react';

//Styles
import '../styles/About.css';

//images
import JSLogo from '../images/JS_Logo.png';
import ReactLogo from '../images/React_Logo.png';
import PythonLogo from '../images/Python_Logo.png'
import Node_Logo from '../images/Node_Logo.png';

const About = () => {
    return (
        <>
            <div className="container flex flex-col justify-center text-white mt-24">

                <div className="px-10 flex flex-col items-center">
                    <h2 className="text-4xl mb-4">About Me</h2>
                    <p className="text-xl"> I’m a dedicated full stack developer with a strong foundation in modern web technologies, including React, Python, PostgreSQL, TypeScript, and TailwindCSS. My journey into programming began through a comprehensive bootcamp with the University of Massachusetts, where I developed the skills to build dynamic, responsive, and scalable web applications. I enjoy bringing ideas to life through clean code, seamless user experiences, and efficient backend systems. Whether it's developing intuitive user interfaces or designing robust databases, I thrive in creating solutions that deliver real-world impact. </p>

                    <button className="border px-5 py-3 mt-5 lg:w-[50vw] ease-in duration-300 hover:bg-yellow-500">Download C/V</button>
                </div>

                
               

            </div>
        </>
    );
};

export default About;