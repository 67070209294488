import React from 'react';
import ReactTypingEffect from 'react-typing-effect';

//Stylesheets
import '../styles/TypingEffect.css'

const TypingEffect = () => {
    return (
        <>
            <ReactTypingEffect
                text={[
                    "a Full-Stack Developer",
                    " driven",
                    " always learning"
                ]}
                cursorRenderer={cursor => <h1 className="blinker">{cursor}</h1>}
                speed="100"
                eraseSpeed={100}
                eraseDelay={2000}
                displayTextRenderer={(text, i) => {
                    return (
                        <h1>
                            {text.split('').map((char, i) => {
                                const key = `${i}`;
                                return (
                                    <span className="TypingEffect-text"
                                        key={key}
                                        style={{color: 'white'}}
                                    >{char}</span>
                                );
                            })}
                        </h1>
                    );
                }}       
            />
        </>
    );
};

export default TypingEffect;