import React, { useCallback } from 'react';
import Navbar from './Navbar';

//components
import TypingEffect from './TypingEffect';


export default function Home(){
    return (
        <>

            <div className='container flex flex-col mx-auto py-[12rem]'>
                <div className='text-white text-4xl lg:text-6xl'>
                    <p>Hello</p>
                    <h1>I'm Jonathan Lopez</h1>

                    <p>
                        I am
                    </p>
                    <TypingEffect />
                </div>
                {/* <button className="text-yellow-300 text-3xl mt-32 mx-auto ease duration-500 hover:rotate-[360deg]">
                        <a href=""><i className="fa-solid fa-chevron-down"></i></a>
                </button> */}

            </div>
        </>
    );
}