import JsLogo from '../images/JS_Logo.png';
import NodeLogo from '../images/Node_Logo.png';
import PythonLogo from '../images/Python_Logo.png';
import ReactLogo from '../images/React_Logo.png';

export default function Logos(){
    return (
        <>
            <div className="">
                <div className="flex flex-col justify-center items-center bg-[#1b2a41] mt-32 lg:w-[100vw]">
                    <h2 className="text-4xl text-white">What I Know</h2>
                    <div className="flex flex-col md:flex-row lg:flex-row justify-center items-center">
                        <img src={JsLogo} className="w-[50%] md:w-[25%] lg:w-[25%]"/>
                        <img src={NodeLogo} className="w-[50%] md:w-[25%] lg:w-[25%]"/>
                        <img src={ReactLogo} className="w-[50%] animate-[spin_10s_ease_infinite] md:w-[25%] lg:w-[25%]"/>
                    </div>
                </div>
            </div>
        
        </>
    );
};