import './App.css'
import Home from './Components/Home'


//Components
import Navbar from './Components/Navbar';
import Three from './Components/Three';
import ParticlesEffect from './Components/ParticlesEffect';
import About from './Components/About';

import { Canvas } from "@react-three/fiber";
import Projects from './Components/Projects';
import Logos from './Components/Logos';

function App() {

  return (
    <div className="overflow-hidden snap-x scroll-smooth">
      <ParticlesEffect/>
      <Navbar />
      <Home />
      <About/>
      <Logos/>
      <Projects/>

    </div>
  );
}

export default App;
